/* You can add global styles to this file, and also import other style files */
@import './variables'; // estas variables están en la librería libs/front/src/lib/styles
@import './fonts';
@import './theme.scss';
@import 'bootstrap/scss/bootstrap';
@import './font-awesome-pro';

// Mixin efecto Pulse
@mixin btn-solicitud {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  opacity: 0;
  margin: auto;
}

body {
  font-family: 'Inter';
	font-weight: 400;
  background: #fcfdff;
  color: map-get($theme-colors, 'text');

  &.sidebar-open {
    overflow: hidden;
  }

  .cdk-overlay-container {
    z-index: 1200 !important;
  }
}

h1,
h2,
h3,
h4 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

button,
.btn {
  &:focus {
    outline: 0;
  }

  &.btn-outline-primary {
    &:hover {
      color: #ffffff !important;
    }
  }

  i.material-icons {
    vertical-align: middle;
    line-height: 0;
  }
}

.modal-transform-none {
  .modal-dialog {
    transform: none !important;
    -webkit-transform: none !important;
  }
}

.btn-pulse {
  position: relative;
  &:before {
    @include btn-solicitud;
    animation: pulse 1.5s infinite linear;
  }
  &:after {
    @include btn-solicitud;
    animation: pulse 2s 0.4s infinite linear;
  }
}

// Pulse
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.help {
  cursor: help;
}

.btn-solicitar {
  color: white;
  padding: 10px 0px 6px 0px;
  line-height: 20px;
  font-size: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
  border-bottom: 5px solid rgba(0, 0, 0, 0.19);
  &:hover {
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0 4px 16px rgba(49, 138, 172, 1);
  }
  fa-icon {
    font-size: 35px;
    margin-right: 10px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  height: 64px;
  width: 100%;
  text-align: center;

  div {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #40b4e5;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #40b4e5 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Estilos para el webChat de Twilio

.Twilio-MainHeader-default {
  justify-content: space-evenly;
}

.Twilio-MainHeader-default div {
  font-size: 1rem !important;
}

.Twilio-EntryPoint,
.Twilio-MainContainer {
  z-index: 1200 !important;
}

.Twilio-MainHeader-default img {
  width: 30px !important;
}

.Twilio-DynamicfORM div {
  margin-top: 12px !important;
}

.Twilio-DynamicForm div div {
  width: 100% !important;
}

ngb-modal-backdrop {
  z-index: 1040 !important;
}
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
